import firebase from "firebase"
import "firebase/functions"
import "firebase/analytics"

export const APP = firebase.initializeApp({
  apiKey: "AIzaSyCNokON927AEXTh2gGrtAOEtw-KzeKO8XM",
  authDomain: "thestonkmarket.firebaseapp.com",
  projectId: "thestonkmarket",
  storageBucket: "thestonkmarket.appspot.com",
  messagingSenderId: "753809140938",
  appId: "1:753809140938:web:845e20642842a5aaa75833",
  measurementId: "G-96QDPN4Z4V",
})

export const DiscordJoin = () => APP.analytics().logEvent("telegramjoin")

export { firebase }
