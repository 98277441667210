import React, { useEffect, useState } from "react"
import styled from "styled-components"
import stonk from "./imgs/stonks.png"
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io"
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"
import { DiscordJoin } from "./utils/firebase"

const StonkGuy = styled.div`
  position: fixed;
  bottom: -100%;
  left: 10vw;
  max-width: min(50vw, 40vh);
  animation: slideUp 0.75s ease-in-out;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
  & > div {
    position: relative;
    width: inherit;
    margin-bottom: -1ch;

    .chat {
      background: #fff;
      position: absolute;
      bottom: 90%;
      left: 90%;
      padding: 3ch 2ch;
      font-size: 3ch;
      min-width: min(75vw, 400px);
      /* white-space: nowrap; */
      border-radius: 1ch 1ch 1ch 0;
      opacity: 0;

      animation: fadeIn 0.5s linear;
      animation-delay: 1.75s;
      animation-fill-mode: forwards;

      b:first-child {
        position: absolute;
        top: 0.5ch;
      }

      svg {
        position: absolute;
        bottom: 0.5ch;
        &.left {
          left: 1ch;
        }
        &.right {
          right: 1ch;
        }
        &:hover {
          cursor: pointer;
        }
      }
      .right {
        position: absolute;
        bottom: 0.5ch;
        right: 1ch;
        font-weight: bold;
        & a {
          color: inherit;
          text-decoration: none;
        }
        &:hover {
          cursor: pointer;
        }
      }

      @media screen and (max-width: 1000px) {
        bottom: 90%;
        left: -10%;
        transform: scale(0.66);
        max-height: 50vh;
      }
    }
  }
  img {
    width: inherit;
    max-width: min(50vw, 40vh);
  }
  a {
    color: #012c95;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 0;
    }
  }
`

const StonkBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  background: #012c95;
  transform: rotateY(48deg) rotateX(13deg) rotateZ(357deg) scale(2.5);
  animation: borderFadeIn 0.5s ease-in-out;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;

  table {
    width: inherit;
    height: inherit;
    color: #012c95;
    animation: textFadeIn 0.5s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-size: 1ch;
    font-family: "Roboto", sans-serif;
    * {
      color: #4684bd;
      box-sizing: border-box;
    }
    tr {
      opacity: 0;
      animation: fadeIn 0.25s linear;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
    }
    td {
      opacity: 0;

      animation: fadeIn 0.5s linear;
      /* animation-delay: 0.25s; */
      animation-fill-mode: forwards;
    }
    tr:nth-child(odd) {
      td:nth-child(odd) {
        background: #012c95;
        animation-delay: 0s;
      }
    }
    tr:nth-child(even) {
      td:nth-child(even) {
        background: #012c95;
        opacity: 0;

        animation: fadeIn 0.25s linear;
        animation-delay: 0s;
        animation-fill-mode: forwards;
      }
    }
    td {
      margin: 2ch;
      background: #041b9b;
      text-align: right;
      padding: 0.5ch 1ch;
    }
    @media screen and (min-width: 450px) {
      font-size: 1.75ch;
    }
    @media screen and (min-width: 768px) {
      font-size: 2.5ch;
    }
  }
  @keyframes borderFadeIn {
    0% {
      background: #012c95;
    }
    100% {
      background: #4684bd;
    }
  }
  @keyframes textFadeIn {
    0% {
      color: #012c95;
    }
    100% {
      color: #4684bd;
    }
  }
`

function App() {
  return (
    <div className="App">
      <Background />
      <StonkGuyChat />
    </div>
  )

  function StonkGuyChat() {
    const MAX_PAGE = 3
    const [page, setPage] = useState<number>(0)

    return (
      <StonkGuy>
        <div>
          <img src={stonk} className="Stonk-guy" alt="Stonk Guy" />
          <div className="chat">
            <b>Stonk Guy</b>
            {page == 0 ? (
              <>"Hey there kid, I heard you like Stonks..."</>
            ) : page == 1 ? (
              <>
                "While I love your excitement <b>The Stonk Market</b> is not yet
                open 😔"
              </>
            ) : page == 2 ? (
              <>
                "Don't worry, you're just arrived earlier than the devs
                expected! In the mean time, you can join the{" "}
                <b>
                  <a
                    href="https://discord.gg/JwhGM8PQSX"
                    target="_blank"
                    onClick={DiscordJoin}
                  >
                    Stonk Market Discord
                  </a>
                </b>{" "}
                to learn more about the project. Tell them <b>Stonk Guy</b> sent
                you."
              </>
            ) : page == 3 ? (
              <>"Okay, I'll be expecting you then!"</>
            ) : (
              ""
            )}
            {page < MAX_PAGE ? (
              page == 0 ? (
                <div onClick={() => nextPage()} className="right">
                  "You bet I do!"
                </div>
              ) : page == 1 ? (
                <div onClick={() => nextPage()} className="right">
                  "Oh..."
                </div>
              ) : page == 2 ? (
                <div
                  onClick={() => {
                    nextPage()
                  }}
                  className="right"
                >
                  <a
                    href="https://discord.gg/JwhGM8PQSX"
                    target="_blank"
                    onClick={DiscordJoin}
                  >
                    "Will do!"
                  </a>
                </div>
              ) : null
            ) : null}
            {page > 0 ? (
              <FaArrowLeft onClick={() => lastPage()} className="left" />
            ) : null}
          </div>
        </div>
      </StonkGuy>
    )

    function nextPage() {
      if (page + 1 <= MAX_PAGE) setPage(page + 1)
    }
    function lastPage() {
      if (page - 1 >= 0) setPage(page - 1)
    }
  }

  function Background() {
    const AMOUNT = 25
    class StonkRow {
      values = Array(9).fill(null).map(PickNumber)
      renderRow(i: number) {
        return (
          <tr key={i}>
            <td>{this.values[0]}</td>
            <td style={{ width: "8ch", textAlign: "center" }}>{UpOrDown()}</td>
            <td>{this.values[1] / 10000}%</td>
            <td>${this.values[2] / 100}</td>
            <td style={{ width: "8ch", textAlign: "center" }}>{UpOrDown()}</td>

            <td>{this.values[3] / 10000}%</td>
            <td>${this.values[4] / 100}</td>
            <td style={{ width: "8ch", textAlign: "center" }}>{UpOrDown()}</td>
            <td>{this.values[5] / 1000}%</td>
            <td>${this.values[6] / 100}</td>
            <td style={{ width: "8ch", textAlign: "center" }}>{UpOrDown()}</td>

            <td>{this.values[7] / 10000}%</td>
            <td>${this.values[8] / 100}</td>
          </tr>
        )
      }
    }

    const rows = Array(AMOUNT)
      .fill(null)
      .map((_, i) => {
        return new StonkRow()
      })
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
      setTimeout(() => setSeconds(seconds + 1), 1000)
    }, [seconds])

    return (
      <StonkBackground>
        <table>{GenerateRows()}</table>
      </StonkBackground>
    )

    function UpOrDown(): React.ReactNode {
      return Math.random() > 0.5 ? (
        <IoMdArrowDropdown size={"3ch"} />
      ) : (
        <IoMdArrowDropup size={"3ch"} />
      )
    }

    function PickNumber() {
      return Math.floor(Math.random() * 5000) + 9000
    }

    function GenerateRows(): React.ReactNode {
      return rows.map((row: StonkRow, i) => {
        row.values = row.values.map(
          (value) => value + Math.floor((Math.random() * 1 - 0.5) * 10)
        )
        return row.renderRow(i)
      })
    }
  }
}

export default App
